import { Tooltip } from "@mui/material";
import propTypes from "prop-types";

const NumberTooltip = ({
  content,
  children,
  className,
  followCursor,
  placement,
}) => {
  const tooltipStyles = {
    tooltip: "tooltip " + className,
    arrow: "arrow",
  };

  return (
    <Tooltip
      title={content}
      //   open={true}
      id="tooltip"
      placement={placement || "top"}
      arrow
      followCursor={!followCursor ? false : true}
      classes={tooltipStyles}
      enterTouchDelay={0}
      leaveTouchDelay={10000}

      // disableHoverListener={window.innerWidth < 600}
    >
      {children}
    </Tooltip>
  );
};

NumberTooltip.propTypes = {
  content: propTypes.any,
  className: propTypes.string | undefined,
  children: propTypes.any | undefined,
  followCursor: propTypes.boolean | undefined,
  placement: "top" | "bottom" | "left" | "right" | undefined,
};
export default NumberTooltip;
