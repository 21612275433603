import cx from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { FaMoon, FaSun, FaTimes } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import { RiMenuLine } from "react-icons/ri";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import bufferIcon from "./img/buffer.svg";
import bufferDarkIcon from "./img/buffer_dark.svg";
import AboveBelow from "./views/AboveBelow";
import Arbitrum from "./views/Arbitrum";
import Polygon from "./views/Polygon";

function AppHeaderLinks({ mode, small, clickCloseIcon }) {
  return (
    <div className="App-header-links">
      {small && (
        <div className="App-header-links-header">
          <div
            className="App-header-menu-icon-block"
            onClick={() => clickCloseIcon()}
          >
            <FiX className="App-header-menu-icon" />
          </div>
          <NavLink
            exact
            activeClassName="active"
            className="App-header-link-main"
            to="/"
          >
            <img
              src={mode == "dark" ? bufferDarkIcon : bufferIcon}
              alt="GMX Logo"
            />
          </NavLink>
        </div>
      )}
      <div className="App-header-link-container">
        <NavLink to="/arbitrum" exact className="nav-link" activeClassName="active">
          Up/Down
        </NavLink>
      </div>
      <div className="App-header-link-container">
        <NavLink to="/above-below" className="nav-link">
          Above/Below
        </NavLink>
      </div>
    </div>
  );
}

const App = () => {
  const [mode, setMode] = useState(null);
  const [isDrawerVisible, setIsDrawerVisible] = useState(undefined);

  const slideVariants = {
    hidden: { x: "-100%" },
    visible: { x: 0 },
  };

  const fadeVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  useEffect(() => {
    const savedMode = window.localStorage.getItem("mode");
    const targetMode = savedMode == "light" ? "light" : "dark";
    document.querySelector("body").style.backgroundColor =
      targetMode == "dark" ? "#101124" : "#f6f9ff";
    setMode(targetMode);
  }, []);

  const switchMode = () => {
    const targetMode = mode == "dark" ? "light" : "dark";
    window.localStorage.setItem("mode", targetMode);
    document.querySelector("body").style.backgroundColor =
      targetMode == "dark" ? "#101124" : "#f6f9ff";
    setMode(targetMode);
  };

  return (
    <Switch>
      {mode && (
        <div className={cx("App", mode)}>
          {isDrawerVisible && (
            <AnimatePresence>
              {isDrawerVisible && (
                <motion.div
                  className="App-header-backdrop"
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={fadeVariants}
                  transition={{ duration: 0.2 }}
                  onClick={() => setIsDrawerVisible(!isDrawerVisible)}
                ></motion.div>
              )}
            </AnimatePresence>
          )}
          <div className="nav">
            <div className="nav-left">
              <div
                className="App-header-menu-icon-block"
                onClick={() => setIsDrawerVisible(!isDrawerVisible)}
              >
                {!isDrawerVisible && (
                  <RiMenuLine className="App-header-menu-icon" />
                )}
                {isDrawerVisible && (
                  <FaTimes className="App-header-menu-icon" />
                )}
              </div>
              <a
                href="https://app.buffer.finance"
                target="_blank"
                className="nav-logo"
                rel="noreferrer"
              >
                <img src={mode == "dark" ? bufferDarkIcon : bufferIcon} />
              </a>

              {/* <NavLink to="/arbitrum" className="nav-link" activeClassName="active">
                Up/Down
              </NavLink>
              <NavLink to="/above-below" className="nav-link">
                Above/Below
              </NavLink> */}
            </div>
            <h1 className="hidden">Analytics</h1>
            <div className="nav-right">
              <a
                href="https://app.buffer.finance"
                target="_blank"
                className="nav-link"
                rel="noreferrer"
              >
                Start Trading
              </a>
              <div className="modeselect" onClick={() => switchMode()}>
                {mode == "dark" ? <FaSun /> : <FaMoon />}
              </div>
            </div>
          </div>
          <AnimatePresence>
            {isDrawerVisible && (
              <motion.div
                onClick={() => setIsDrawerVisible(false)}
                className="App-header-links-container App-header-drawer"
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={slideVariants}
                transition={{ duration: 0.2 }}
              >
                <AppHeaderLinks
                  mode={mode}
                  small
                  clickCloseIcon={() => setIsDrawerVisible(false)}
                />
              </motion.div>
            )}
          </AnimatePresence>
          <div className="content">
          <Route path="/" exact>
              <Redirect to="/arbitrum" />
            </Route>

            <Route
              exact
              path="/arbitrum"
              render={(props) => (
                <Arbitrum {...props} mode={mode} chain={"Arbitrum"} product={"Up/Down"}/>
              )}
            />
            <Route
              exact
              path="/polygon"
              render={(props) => (
                <Polygon {...props} mode={mode} chain={"Polygon"} product={"Up/Down"}/>
              )}
            />
            <Route
              exact
              path="/above-below"
              render={(props) => (
                <AboveBelow {...props} mode={mode} chain={"Arbitrum"} product={"Above/Below"}/>
              )}
            />

          </div>
        </div>
      )}
    </Switch>
  );
};

export default App;
