import { chain, maxBy, minBy, sortBy, sumBy } from "lodash";
import { useMemo } from "react";
import {
  FIRST_DATE_TS,
  MOVING_AVERAGE_DAYS,
  MOVING_AVERAGE_PERIOD,
  NOW_TS,
  useGraph,
} from "./dataProvider";

const aboveBelowGraphUrl =
  "https://subgraph.satsuma-prod.com/e66b06ce96d2/bufferfinance/v2.5-arbitrum-mainnet/api";

export function useABVolumeData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "arbitrum",
} = {}) {
  const PROPS = "amount VolumeUSDC VolumeARB VolumeBFR".split(" ");
  const timestampProp = "timestamp";
  const query = `{
      abvolumeStats(
        first: 10000,
        orderBy: ${timestampProp},
        orderDirection: desc
        where: { period: daily, ${timestampProp}_gte: ${from}, ${timestampProp}_lte: ${to} }
        subgraphError: allow
      ) {
        ${timestampProp}
        amount
        ${PROPS.join("\n")}
      }
    }`;
  const [graphData, loading, error] = useGraph(
    query,
    chainName,
    aboveBelowGraphUrl
  );
  const data = useMemo(() => {
    if (!graphData) {
      return null;
    }

    let ret = sortBy(graphData.abvolumeStats, timestampProp).map((item) => {
      const ret = { timestamp: item[timestampProp] };
      let all = 0;
      PROPS.forEach((prop) => {
        ret[prop] = +item[prop] / 1e6;
        if (prop === "amount") all += ret[prop];
      });
      ret.all = all;
      return ret;
    });

    let cumulative = 0;
    const cumulativeByTs = {};
    return ret.map((item) => {
      cumulative += item.all;

      let movingAverageAll;
      const movingAverageTs = item.timestamp - MOVING_AVERAGE_PERIOD;
      if (movingAverageTs in cumulativeByTs) {
        movingAverageAll =
          (cumulative - cumulativeByTs[movingAverageTs]) / MOVING_AVERAGE_DAYS;
      }

      return {
        movingAverageAll,
        cumulative,
        ...item,
      };
    });
  }, [graphData]);
  return [data, loading, error];
}

export function useAbFeesData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "arbitrum",
} = {}) {
  const PROPS = "fee feeARB feeUSDC feeBFR".split(" ");
  const feesQuery = `{
      abfeeStats(
        first: 10000
        orderBy: timestamp
        orderDirection: desc
        where: { period: daily, timestamp_gte: ${from}, timestamp_lte: ${to}}
        subgraphError: allow
      ) {
        timestamp 
        ${PROPS.join("\n")}
      }
    }`;
  let [feesData, loading, error] = useGraph(
    feesQuery,
    chainName,
    aboveBelowGraphUrl
  );

  const feesChartData = useMemo(() => {
    if (!feesData) {
      return null;
    }

    let chartData = sortBy(feesData.abfeeStats, "timestamp").map((item) => {
      const ret = { timestamp: item.timestamp };
      let all = 0;
      PROPS.forEach((prop) => {
        if (item[prop]) {
          ret[prop] = +item[prop];
          if (prop === "fee") all += ret[prop];
        }
      });
      ret.all = all;
      ret.liquidation = item.marginAndLiquidation - item.margin;
      return ret;
    });

    let cumulative = 0;
    const cumulativeByTs = {};
    return chain(chartData)
      .groupBy((item) => item.timestamp)
      .map((values, timestamp) => {
        const all = sumBy(values, "all");
        cumulative += all;

        let movingAverageAll;
        const movingAverageTs = timestamp - MOVING_AVERAGE_PERIOD;
        if (movingAverageTs in cumulativeByTs) {
          movingAverageAll =
            (cumulative - cumulativeByTs[movingAverageTs]) /
            MOVING_AVERAGE_DAYS;
        }

        const ret = {
          timestamp: Number(timestamp),
          all: all / 1e6,
          cumulative: cumulative / 1e6,
          movingAverageAll,
        };
        PROPS.forEach((prop) => {
          ret[prop] = sumBy(values, prop) / 1e6;
        });
        cumulativeByTs[timestamp] = cumulative;
        return ret;
      })
      .value()
      .filter((item) => item.timestamp >= from);
  }, [feesData]);
  return [feesChartData, loading, error];
}

export function useABUsersData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "arbitrum",
} = {}) {
  const query = `{
    abuserStats(
      first: 10000
      orderBy: timestamp
      orderDirection: desc
      where: { period: daily, timestamp_gte: ${from}, timestamp_lte: ${to} }
      subgraphError: allow
    ) {
      existingCount
      uniqueCount
      uniqueCountCumulative
      timestamp
    }
  }`;
  const [graphData, loading, error] = useGraph(
    query,
    chainName,
    aboveBelowGraphUrl
  );

  let cumulativeNewUserCount = 0;
  const data = graphData
    ? sortBy(graphData.abuserStats, "timestamp").map((item) => {
        cumulativeNewUserCount += item.uniqueCount;
        const oldCount = item.existingCount;
        const totalCount = item.uniqueCount + oldCount;
        const oldPercent = ((oldCount / totalCount) * 100).toFixed(1);
        return {
          all: item.uniqueCount + oldCount,
          newCount: item.uniqueCount,
          uniqueSum: 0,
          oldCount,
          oldPercent,
          cumulativeNewUserCount,
          ...item,
          timestamp: item.timestamp,
        };
      })
    : null;

  return [data, loading, error];
}

export function useABTradersUSDCData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "arbitrum",
} = {}) {
  const [closedPositionsData, loading] = useGraph(
    `{
  abtradingStats(
      orderBy: timestamp
      orderDirection: desc
      first: 10000
      where: { period: daily,timestamp_gte: ${from}, timestamp_lte: ${to}}
      subgraphError: allow
  ) {
    lossUSDC
    profitUSDC
    profitCumulativeUSDC
    timestamp
    lossCumulativeUSDC
  }

  }`,
    chainName,
    aboveBelowGraphUrl
  );
  let ret = null;
  let currentPnlCumulative = 0;
  let currentProfitCumulative = 0;
  let currentLossCumulative = 0;

  const data =
    closedPositionsData && closedPositionsData.abtradingStats.length
      ? sortBy(closedPositionsData.abtradingStats, (i) => i.timestamp).map(
          (dataItem) => {
            const profit = +dataItem.profitUSDC / 1e6;
            const loss = +dataItem.lossUSDC / 1e6;
            const profitCumulative = +dataItem.profitCumulativeUSDC / 1e6;
            const lossCumulative = +dataItem.lossCumulativeUSDC / 1e6;
            const pnlCumulative = profitCumulative - lossCumulative;
            const pnl = profit - loss;
            currentProfitCumulative += profit;
            currentLossCumulative -= loss;
            currentPnlCumulative += pnl;
            return {
              profit,
              loss: -loss,
              profitCumulative,
              lossCumulative: -lossCumulative,
              pnl,
              pnlCumulative,
              timestamp: dataItem.timestamp,
              currentPnlCumulative,
              currentLossCumulative,
              currentProfitCumulative,
            };
          }
        )
      : null;

  if (data) {
    const maxProfit = maxBy(data, (item) => item.profit).profit;
    const maxLoss = minBy(data, (item) => item.loss).loss;
    const maxProfitLoss = Math.max(maxProfit, -maxLoss);

    const maxPnl = maxBy(data, (item) => item.pnl).pnl;
    const minPnl = minBy(data, (item) => item.pnl).pnl;
    const maxCurrentCumulativePnl = maxBy(
      data,
      (item) => item.currentPnlCumulative
    ).currentPnlCumulative;
    const minCurrentCumulativePnl = minBy(
      data,
      (item) => item.currentPnlCumulative
    ).currentPnlCumulative;

    const currentProfitCumulative =
      data[data.length - 1].currentProfitCumulative;
    const currentLossCumulative = data[data.length - 1].currentLossCumulative;
    const stats = {
      maxProfit,
      maxLoss,
      maxProfitLoss,
      currentProfitCumulative,
      currentLossCumulative,
      maxCurrentCumulativeProfitLoss: Math.max(
        currentProfitCumulative,
        -currentLossCumulative
      ),

      maxAbsPnl: Math.max(Math.abs(maxPnl), Math.abs(minPnl)),
      maxAbsCumulativePnl: Math.max(
        Math.abs(maxCurrentCumulativePnl),
        Math.abs(minCurrentCumulativePnl)
      ),
    };

    ret = {
      data,
      stats,
    };
  }

  return [ret, loading];
}

export function useABTradersARBData({
  from = FIRST_DATE_TS,
  to = NOW_TS,
  chainName = "arbitrum",
} = {}) {
  const [closedPositionsData, loading] = useGraph(
    `{
  abtradingStats(
      orderBy: timestamp
      orderDirection: desc
      first: 10000
      where: { period: daily,timestamp_gte: ${from}, timestamp_lte: ${to}}
      subgraphError: allow
  ) {
    lossARB
    profitARB
    profitCumulativeARB
    timestamp
    lossCumulativeARB
  }

  }`,
    chainName,
    aboveBelowGraphUrl
  );
  let ret = null;
  let currentPnlCumulative = 0;
  let currentProfitCumulative = 0;
  let currentLossCumulative = 0;

  const data =
    closedPositionsData && closedPositionsData.abtradingStats.length
      ? sortBy(closedPositionsData.abtradingStats, (i) => i.timestamp).map(
          (dataItem) => {
            const profit = +dataItem.profitARB / 1e6;
            const loss = +dataItem.lossARB / 1e6;
            const profitCumulative = +dataItem.profitCumulativeARB / 1e6;
            const lossCumulative = +dataItem.lossCumulativeARB / 1e6;
            const pnlCumulative = profitCumulative - lossCumulative;
            const pnl = profit - loss;
            currentProfitCumulative += profit;
            currentLossCumulative -= loss;
            currentPnlCumulative += pnl;
            return {
              profit,
              loss: -loss,
              profitCumulative,
              lossCumulative: -lossCumulative,
              pnl,
              pnlCumulative,
              timestamp: dataItem.timestamp,
              currentPnlCumulative,
              currentLossCumulative,
              currentProfitCumulative,
            };
          }
        )
      : null;

  if (data) {
    // console.log(data,'data')
    const maxProfit = maxBy(data, (item) => item.profit).profit;
    const maxLoss = minBy(data, (item) => item.loss).loss;
    const maxProfitLoss = Math.max(maxProfit, -maxLoss);

    const maxPnl = maxBy(data, (item) => item.pnl).pnl;
    const minPnl = minBy(data, (item) => item.pnl).pnl;
    const maxCurrentCumulativePnl = maxBy(
      data,
      (item) => item.currentPnlCumulative
    ).currentPnlCumulative;
    const minCurrentCumulativePnl = minBy(
      data,
      (item) => item.currentPnlCumulative
    ).currentPnlCumulative;

    const currentProfitCumulative =
      data[data.length - 1].currentProfitCumulative;
    const currentLossCumulative = data[data.length - 1].currentLossCumulative;
    const stats = {
      maxProfit,
      maxLoss,
      maxProfitLoss,
      currentProfitCumulative,
      currentLossCumulative,
      maxCurrentCumulativeProfitLoss: Math.max(
        currentProfitCumulative,
        -currentLossCumulative
      ),

      maxAbsPnl: Math.max(Math.abs(maxPnl), Math.abs(minPnl)),
      maxAbsCumulativePnl: Math.max(
        Math.abs(maxCurrentCumulativePnl),
        Math.abs(minCurrentCumulativePnl)
      ),
    };

    ret = {
      data,
      stats,
    };
  }

  return [ret, loading];
}

export function useTotalABData({ chainName }) {
  const query = `{
    USDCstats:dashboardStat ( id:"AB-total") {
      totalSettlementFees
      totalTrades
      totalVolume
    }

    totalTraders:abuserStats(where: {period: total}) {
      uniqueCountCumulative
    }
    
    dashboardStats(where: {id: "AB-total"}) {
      openInterest
    }
  }`;
  const [graphData, loading, error] = useGraph(
    query,
    chainName,
    aboveBelowGraphUrl
  );

  // console.log(graphData, 'graphData')
  const data = graphData
    ? {
        totalVolume: graphData.USDCstats.totalVolume / 1e6,
        totalFees: graphData.USDCstats.totalSettlementFees / 1e6,
        totalUsers: graphData.totalTraders?.[0].uniqueCountCumulative,
        openInterest: graphData.dashboardStats?.[0].openInterest / 1e6,
      }
    : { totalVolume: null, totalFees: null, totalUsers: null };

  return [data, loading, error];
}

export function useABDeltaStats({ chainName }) {
  const prevDayEpoch = Math.floor((Date.now() - 24 * 60 * 60 * 1000) / 1000);

  const query = `{
    USDC24stats:abvolumePerContracts(
      orderBy: timestamp
      orderDirection: desc
      first: 10000
      where: {depositToken: "USDC", timestamp_gt: ${prevDayEpoch}}
    ) {
        amount
        settlementFee
    }
  }`;
  const [data] = useGraph(query, chainName, aboveBelowGraphUrl);
  const USDC24hrsStats = useMemo(() => {
    if (data?.USDC24stats) {
      return {
        ...data.USDC24stats.reduce(
          (acc, curr) => {
            return {
              amount: +acc.amount + +curr.amount,
              settlementFee: +acc.settlementFee + +curr.settlementFee,
            };
          },
          { amount: "0", settlementFee: "0" }
        ),
      };
    }
    return null;
  }, [data?.USDC24stats]);

  return [
    USDC24hrsStats?.settlementFee / 1e6 || 0,
    USDC24hrsStats?.amount / 1e6 || 0,
  ];
}

export function useABTradersData({ chainName = "arbitrum" }) {
  const [closedPositionsData, loading] = useGraph(
    `{
  tradingStats:abtradingStats(
      orderBy: timestamp
      orderDirection: desc
      first:10000
      where: { period: daily}
      subgraphError: allow
  ) {
    loss
    profit
    profitCumulative
    timestamp
    lossCumulative

  }

  }`,
    chainName,
    aboveBelowGraphUrl
  );
  let ret = null;
  let currentPnlCumulative = 0;
  let currentProfitCumulative = 0;
  let currentLossCumulative = 0;

  const data =
    closedPositionsData && closedPositionsData.tradingStats.length
      ? sortBy(closedPositionsData.tradingStats, (i) => i.timestamp).map(
          (dataItem) => {
            const profit = +dataItem.profit / 1e6;
            const loss = +dataItem.loss / 1e6;
            const profitCumulative = +dataItem.profitCumulative / 1e6;
            const lossCumulative = +dataItem.lossCumulative / 1e6;
            const pnlCumulative = profitCumulative - lossCumulative;
            const pnl = profit - loss;
            currentProfitCumulative += profit;
            currentLossCumulative -= loss;
            currentPnlCumulative += pnl;
            return {
              profit,
              loss: -loss,
              profitCumulative,
              lossCumulative: -lossCumulative,
              pnl,
              pnlCumulative,
              timestamp: dataItem.timestamp,
              currentPnlCumulative,
              currentLossCumulative,
              currentProfitCumulative,
            };
          }
        )
      : null;

  if (data) {
    const maxProfit = maxBy(data, (item) => item.profit).profit;
    const maxLoss = minBy(data, (item) => item.loss).loss;
    const maxProfitLoss = Math.max(maxProfit, -maxLoss);

    const maxPnl = maxBy(data, (item) => item.pnl).pnl;
    const minPnl = minBy(data, (item) => item.pnl).pnl;
    const maxCurrentCumulativePnl = maxBy(
      data,
      (item) => item.currentPnlCumulative
    ).currentPnlCumulative;
    const minCurrentCumulativePnl = minBy(
      data,
      (item) => item.currentPnlCumulative
    ).currentPnlCumulative;

    const currentProfitCumulative =
      data[data.length - 1].currentProfitCumulative;
    const currentLossCumulative = data[data.length - 1].currentLossCumulative;
    const stats = {
      maxProfit,
      maxLoss,
      maxProfitLoss,
      currentProfitCumulative,
      currentLossCumulative,
      maxCurrentCumulativeProfitLoss: Math.max(
        currentProfitCumulative,
        -currentLossCumulative
      ),

      maxAbsPnl: Math.max(Math.abs(maxPnl), Math.abs(minPnl)),
      maxAbsCumulativePnl: Math.max(
        Math.abs(maxCurrentCumulativePnl),
        Math.abs(minCurrentCumulativePnl)
      ),
    };

    ret = {
      data,
      stats,
    };
  }

  return [ret, loading];
}
