import moment from "moment";
import React, { useMemo, useState } from "react";

import {
  CHART_HEIGHT,
  COLORS,
  YAXIS_WIDTH,
  formatNumber,
  tooltipFormatter,
  tooltipFormatterNumber,
  tooltipLabelFormatter,
  tooltipLabelFormatterUnits,
  yaxisFormatter,
  yaxisFormatterNumber,
} from "../helpers";

import { Tooltip } from "@mui/material";
import cx from "classnames";
import { RiLoader5Fill } from "react-icons/ri";
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import {
  useABDeltaStats,
  useABTradersARBData,
  useABTradersData,
  useABTradersUSDCData,
  useABUsersData,
  useABVolumeData,
  useAbFeesData,
  useTotalABData,
} from "../aboveBelowDataProvider";
import FeesChart from "../components/ARBfeesChart";
import VolumeChart from "../components/ARBvolumeChart";
import ChartWrapper from "../components/ChartWrapper";
import DateRangeSelect from "../components/DateRangeSelect";
import GenericChart from "../components/GenericChart";
import TradersProfitLossChart from "../components/TradersProfitLossChart";
import ProductDropDown from "./Dropdown/ProductDropDown";

const NOW = Math.floor(Date.now() / 1000);

function AboveBelow(props) {
  const DEFAULT_GROUP_PERIOD = 86400;
  const [groupPeriod] = useState(DEFAULT_GROUP_PERIOD);

  const [dataRange, setDataRange] = useState({
    fromValue: moment().subtract(2, "month").toDate(),
    toValue: null,
  });
  // console.log(dataRange, 'dataRange')
  const { mode,product } = props;

  const from = dataRange.fromValue
    ? Math.floor(+new Date(dataRange.fromValue) / 1000)
    : undefined;
  const to = dataRange.toValue
    ? Math.floor(+new Date(dataRange.toValue) / 1000)
    : NOW;

  const params = { from, to, groupPeriod };

  const [volumeData, volumeLoading] = useABVolumeData(params);

  const [feesData, feesLoading] = useAbFeesData(params);

  const [tradersARBData, tradersARBLoading] = useABTradersARBData(params);
  const [tradersUSDCData, tradersUSDCLoading] = useABTradersUSDCData(params);
  const [tradersData, tradersLoading] = useABTradersData({
    chainName: "arbitrum",
  });
  const [payout, payoutDelta] = useMemo(() => {
    if (!tradersData) {
      return [];
    }

    const payout = tradersData.data[tradersData.data.length - 1]?.pnlCumulative;
    const payoutDelta =
      payout - tradersData.data[tradersData.data.length - 2]?.pnlCumulative;
    return [payout, payoutDelta];
  }, [tradersData]);

  const [usersData, usersLoading] = useABUsersData(params);
  const [, totalUsersDelta] = useMemo(() => {
    if (!usersData) {
      return [null, null];
    }
    const total = usersData[usersData.length - 1]?.cumulativeNewUserCount;
    const prevTotal = usersData[usersData.length - 2]?.cumulativeNewUserCount;
    const delta = total && prevTotal ? total - prevTotal : null;
    return [total, delta];
  }, [usersData]);

  const [{ totalVolume, totalFees, totalUsers, openInterest }] = useTotalABData(
    { chainName: "arbitrum" }
  );

  const [totalFeesDelta, totalVolumeDelta] = useABDeltaStats({
    chainName: "arbitrum",
  });

  const onDateRangeChange = (dates) => {
    const [start, end] = dates;
    setDataRange({ fromValue: start, toValue: end });
  };

  const dateRangeOptions = [
    {
      label: "Last Month",
      id: 1,
    },
    {
      label: "Last 2 Months",
      id: 2,
      isDefault: true,
    },
    {
      label: "Last 3 Months",
      id: 3,
    },
    {
      label: "All time",
      id: 4,
    },
  ];

  return (
    <div className="Home">
      <div className="page-title-section">
        <div className="page-title-block">
          <h1>Chain / Arbitrum</h1>
        </div>
        <div className="page-title-section"><div className="page-title-block nav-right"><h1>Product&nbsp;&nbsp;</h1><ProductDropDown product={product}/></div></div>
        <div className="form">
        <span className="nav-link">
          Date Range</span>          <DateRangeSelect
            options={dateRangeOptions}
            startDate={dataRange.fromValue}
            endDate={dataRange.toValue}
            onChange={onDateRangeChange}
          />
        </div>
      </div>
      <div className="ab-stats-flex">
        <div className="chart-cell stats">
          {totalVolume ? (
            <>
              <div className="total-stat-label">Total Volume</div>
              <div className="total-stat-value">
                {formatNumber(totalVolume, { currency: true })}
                <span
                  className="total-stat-delta plus"
                  title="Change since previous day"
                >
                  +
                  {formatNumber(totalVolumeDelta, {
                    currency: true,
                    compact: true,
                  })}
                </span>
              </div>
            </>
          ) : (
            <RiLoader5Fill size="3em" className="loader" />
          )}
        </div>
        <div className="chart-cell stats">
          {totalFees ? (
            <>
              <div className="total-stat-label">Total Fees</div>
              <div className="total-stat-value">
                {formatNumber(totalFees, { currency: true })}
                <span
                  className="total-stat-delta plus"
                  title="Change since previous day"
                >
                  +
                  {formatNumber(totalFeesDelta, {
                    currency: true,
                    compact: true,
                  })}
                </span>
              </div>
            </>
          ) : (
            <RiLoader5Fill size="3em" className="loader" />
          )}
        </div>
        <div className="chart-cell stats">
          {totalUsers ? (
            <>
              <div className="total-stat-label">Total Users</div>
              <div className="total-stat-value">
                {formatNumber(totalUsers)}
                <span
                  className="total-stat-delta plus"
                  title="Change since previous day"
                >
                  +{totalUsersDelta}
                </span>
              </div>
            </>
          ) : usersLoading ? (
            <RiLoader5Fill size="3em" className="loader" />
          ) : null}
        </div>
        <div className="chart-cell stats">
          {payout ? (
            <>
              <div className="total-stat-label">Net Payout</div>
              <div className="total-stat-value">
                {formatNumber(payout, { currency: true })}
                <span
                  className={cx(
                    "total-stat-delta",
                    payoutDelta > 0 ? "plus" : "minus"
                  )}
                  title="Change since previous day"
                >
                  {payoutDelta > 0 ? "+" : ""}
                  {formatNumber(payoutDelta, {
                    currency: true,
                    compact: true,
                  })}
                </span>
              </div>
            </>
          ) : tradersLoading ? (
            <RiLoader5Fill size="3em" className="loader" />
          ) : null}
        </div>
      </div>
      <div className="chart-grid">
        <div className="chart-cell">
          <VolumeChart
            data={volumeData}
            loading={volumeLoading}
            chartHeight={CHART_HEIGHT}
            yaxisWidth={YAXIS_WIDTH}
            xaxisTickFormatter={tooltipLabelFormatter}
            yaxisTickFormatter={yaxisFormatter}
            tooltipLabelFormatter={tooltipLabelFormatter}
            tooltipFormatter={tooltipFormatter}
          />
        </div>
        <div className="chart-cell">
          <FeesChart
            data={feesData}
            loading={feesLoading}
            chartHeight={CHART_HEIGHT}
            yaxisWidth={YAXIS_WIDTH}
          />
        </div>

        <div className="chart-cell">
          <ChartWrapper
            title="Traders Net PnL (USDC)"
            loading={tradersUSDCLoading}
            data={tradersUSDCData?.data}
            tooltip={
              "The following graph showcases the trader's Profit and Loss (PnL) by exclusively considering the additions and removals made to the pool, while disregarding any protocol fees."
            }
            csvFields={[
              { key: "pnl", name: "Net PnL(USDC)" },
              { key: "pnlCumulative", name: "Cumulative PnL(USDC)" },
            ]}
          >
            <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
              <ComposedChart data={tradersUSDCData?.data} syncId="tradersId">
                <CartesianGrid strokeDasharray="10 10" />
                <XAxis
                  dataKey="timestamp"
                  tickFormatter={tooltipLabelFormatter}
                  minTickGap={30}
                />
                <YAxis
                  domain={[
                    -tradersUSDCData?.stats.maxAbsCumulativePnl * 1.05,
                    tradersUSDCData?.stats.maxAbsCumulativePnl * 1.05,
                  ]}
                  orientation="right"
                  yAxisId="right"
                  tickFormatter={yaxisFormatter}
                  width={YAXIS_WIDTH}
                  tick={{ fill: COLORS[4] }}
                />
                <YAxis
                  domain={[
                    -tradersUSDCData?.stats.maxAbsPnl * 1.05,
                    tradersUSDCData?.stats.maxAbsPnl * 1.05,
                  ]}
                  tickFormatter={yaxisFormatter}
                  width={YAXIS_WIDTH}
                />
                <Tooltip
                  formatter={tooltipFormatter}
                  labelFormatter={tooltipLabelFormatter}
                  contentStyle={{ textAlign: "left" }}
                />
                <Legend />
                <Bar
                  type="monotone"
                  fill={mode == "dark" ? "#FFFFFF" : "#000000"}
                  dot={false}
                  dataKey="pnl"
                  name="Net PnL"
                >
                  {(tradersUSDCData?.data || []).map((item, i) => {
                    return (
                      <Cell
                        key={`cell-${i}`}
                        fill={item.pnl > 0 ? "#22c761" : "#f93333"}
                      />
                    );
                  })}
                </Bar>
                <Line
                  type="monotone"
                  strokeWidth={2}
                  stroke={COLORS[4]}
                  dataKey="currentPnlCumulative"
                  name="Cumulative PnL"
                  yAxisId="right"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </ChartWrapper>
        </div>
        <div className="chart-cell">
          <TradersProfitLossChart
            syncId="tradersId"
            loading={tradersUSDCLoading}
            tradersData={tradersUSDCData}
            yaxisWidth={YAXIS_WIDTH}
            chartHeight={CHART_HEIGHT}
            csvFields={[
              { key: "profit", name: "profit(USDC)" },
              { key: "loss", name: "loss(USDC)" },
              { key: "profitCumulative", name: "profitCumulative(USDC)" },
              { key: "lossCumulative", name: "lossCumulative(USDC)" },
            ]}
            title={"Traders Profit vs. Loss (USDC)"}
          />
        </div>
        <div className="chart-cell">
          <ChartWrapper
            title="Traders Net PnL (ARB)"
            loading={tradersARBLoading}
            tooltip={
              "The following graph showcases the trader's Profit and Loss (PnL) by exclusively considering the additions and removals made to the pool, while disregarding any protocol fees."
            }
            data={tradersARBData?.data}
            csvFields={[
              { key: "pnl", name: "Net PnL(ARB)" },
              { key: "pnlCumulative", name: "Cumulative PnL(ARB)" },
            ]}
          >
            <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
              <ComposedChart data={tradersARBData?.data} syncId="tradersId">
                <CartesianGrid strokeDasharray="10 10" />
                <XAxis
                  dataKey="timestamp"
                  tickFormatter={tooltipLabelFormatter}
                  minTickGap={30}
                />
                <YAxis
                  domain={[
                    -tradersARBData?.stats.maxAbsCumulativePnl * 1.05,
                    tradersARBData?.stats.maxAbsCumulativePnl * 1.05,
                  ]}
                  orientation="right"
                  yAxisId="right"
                  tickFormatter={yaxisFormatter}
                  width={YAXIS_WIDTH}
                  tick={{ fill: COLORS[4] }}
                />
                <YAxis
                  domain={[
                    -tradersARBData?.stats.maxAbsPnl * 1.05,
                    tradersARBData?.stats.maxAbsPnl * 1.05,
                  ]}
                  tickFormatter={yaxisFormatter}
                  width={YAXIS_WIDTH}
                />
                <Tooltip
                  formatter={tooltipFormatter}
                  labelFormatter={tooltipLabelFormatter}
                  contentStyle={{ textAlign: "left" }}
                />
                <Legend />
                <Bar
                  type="monotone"
                  fill={mode == "dark" ? "#FFFFFF" : "#000000"}
                  dot={false}
                  dataKey="pnl"
                  name="Net PnL"
                >
                  {(tradersARBData?.data || []).map((item, i) => {
                    return (
                      <Cell
                        key={`cell-${i}`}
                        fill={item.pnl > 0 ? "#22c761" : "#f93333"}
                      />
                    );
                  })}
                </Bar>
                <Line
                  type="monotone"
                  strokeWidth={2}
                  stroke={COLORS[4]}
                  dataKey="currentPnlCumulative"
                  name="Cumulative PnL"
                  yAxisId="right"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </ChartWrapper>
        </div>
        <div className="chart-cell">
          <TradersProfitLossChart
            syncId="tradersId"
            loading={tradersARBLoading}
            tradersData={tradersARBData}
            yaxisWidth={YAXIS_WIDTH}
            chartHeight={CHART_HEIGHT}
            csvFields={[
              { key: "profit", name: "profit(ARB)" },
              { key: "loss", name: "loss(ARB)" },
              { key: "profitCumulative", name: "profitCumulative(ARB)" },
              { key: "lossCumulative", name: "lossCumulative(ARB)" },
            ]}
            title={"Traders Profit vs. Loss (ARB)"}
          />
        </div>
        <div className="chart-cell">
          <GenericChart
            loading={usersLoading}
            title="New vs. Existing Users"
            data={usersData?.map((item) => ({ ...item, all: item.all }))}
            yaxisDataKey="all"
            rightYaxisDataKey="oldPercent"
            rightYAxisDomain={[0, 100]}
            yaxisTickFormatter={yaxisFormatterNumber}
            tooltipFormatter={tooltipFormatterNumber}
            tooltipLabelFormatter={tooltipLabelFormatterUnits}
            items={[
              { key: "newCount", name: "New" },
              { key: "oldCount", name: "Existing" },
              {
                key: "oldPercent",
                name: "Existing %",
                yAxisId: "right",
                type: "Line",
                strokeWidth: 2,
                color: COLORS[4],
                unit: "%",
              },
            ]}
            type="Composed"
          />
        </div>

        {/* <div className="chart-cell">
          <BurnBFRchart
            data={burnData}
            loading={burnLoading}
            chartHeight={CHART_HEIGHT}
            yaxisWidth={YAXIS_WIDTH}
          />
        </div>
        <div className="chart-cell">
          <ChartWrapper
            title="USD/BLP rate"
            loading={glpLoading}
            data={glpData}
            csvFields={[{ key: "rate", name: "Exchange Rate" }]}
          >
            <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
              <LineChart data={glpData} syncId="syncGlp">
                <CartesianGrid strokeDasharray="10 10" />
                <XAxis
                  dataKey="timestamp"
                  tickFormatter={tooltipLabelFormatter}
                  minTickGap={30}
                />
                <YAxis
                  dataKey="rate"
                  domain={[glpStats?.minRate * 0.95, glpStats?.maxRate * 1.05]}
                  tickFormatter={(value) => value.toFixed(3)}
                  width={YAXIS_WIDTH}
                />
                <Tooltip
                  formatter={tooltipFormatterNumber}
                  labelFormatter={tooltipLabelFormatter}
                  contentStyle={{ textAlign: "left" }}
                />
                <Legend />
                <Line
                  isAnimationActive={false}
                  type="monotone"
                  strokeWidth={2}
                  unit="$"
                  dot={false}
                  dataKey="rate"
                  stackId="a"
                  name="Exchange Rate"
                  stroke={COLORS[0]}
                />
              </LineChart>
            </ResponsiveContainer>
          </ChartWrapper>
        </div>

        <div className="chart-cell">
          <ChartWrapper
            title="BLP Pool"
            loading={glpLoading}
            data={glpData}
            csvFields={[{ key: "glpSupply", name: "Current USDC Balance" }]}
          >
            <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
              <ComposedChart data={glpData} syncId="tradersId">
                <CartesianGrid strokeDasharray="10 10" />
                <XAxis
                  dataKey="timestamp"
                  tickFormatter={tooltipLabelFormatter}
                  minTickGap={30}
                />
                <YAxis
                  domain={[0, glpStats?.maxGlpAmount * 1.05]}
                  tickFormatter={yaxisFormatter}
                  width={YAXIS_WIDTH}
                  orientation="left"
                  yAxisId="left"
                />
                <Tooltip
                  formatter={tooltipFormatter}
                  labelFormatter={tooltipLabelFormatter}
                  contentStyle={{ textAlign: "left" }}
                />
                <Legend />
                <Line
                  type="monotone"
                  strokeWidth={2}
                  stroke={COLORS[4]}
                  dataKey="glpSupply"
                  name="Current USDC Balance"
                  yAxisId="left"
                  dot={false}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </ChartWrapper>
        </div>

        

        <div className="chart-cell">
          <ChartWrapper
            title="Traders Net PnL (BFR)"
            loading={tradersBFRLoading}
            data={tradersBFRData?.data}
            tooltip={
              "The following graph showcases the trader's Profit and Loss (PnL) by exclusively considering the additions and removals made to the pool, while disregarding any protocol fees."
            }
            csvFields={[
              { key: "pnl", name: "Net PnL(BFR)" },
              { key: "pnlCumulative", name: "Cumulative PnL(BFR)" },
            ]}
          >
            <ResponsiveContainer width="100%" height={CHART_HEIGHT}>
              <ComposedChart data={tradersBFRData?.data} syncId="tradersId">
                <CartesianGrid strokeDasharray="10 10" />
                <XAxis
                  dataKey="timestamp"
                  tickFormatter={tooltipLabelFormatter}
                  minTickGap={30}
                />
                <YAxis
                  domain={[
                    -tradersBFRData?.stats.maxAbsCumulativePnl * 1.05,
                    tradersBFRData?.stats.maxAbsCumulativePnl * 1.05,
                  ]}
                  orientation="right"
                  yAxisId="right"
                  tickFormatter={yaxisFormatter}
                  width={YAXIS_WIDTH}
                  tick={{ fill: COLORS[4] }}
                />
                <YAxis
                  domain={[
                    -tradersBFRData?.stats.maxAbsPnl * 1.05,
                    tradersBFRData?.stats.maxAbsPnl * 1.05,
                  ]}
                  tickFormatter={yaxisFormatter}
                  width={YAXIS_WIDTH}
                />
                <Tooltip
                  formatter={tooltipFormatter}
                  labelFormatter={tooltipLabelFormatter}
                  contentStyle={{ textAlign: "left" }}
                />
                <Legend />
                <Bar
                  type="monotone"
                  fill={mode == "dark" ? "#FFFFFF" : "#000000"}
                  dot={false}
                  dataKey="pnl"
                  name="Net PnL"
                >
                  {(tradersBFRData?.data || []).map((item, i) => {
                    return (
                      <Cell
                        key={`cell-${i}`}
                        fill={item.pnl > 0 ? "#22c761" : "#f93333"}
                      />
                    );
                  })}
                </Bar>
                <Line
                  type="monotone"
                  strokeWidth={2}
                  stroke={COLORS[4]}
                  dataKey="currentPnlCumulative"
                  name="Cumulative PnL"
                  yAxisId="right"
                />
              </ComposedChart>
            </ResponsiveContainer>
          </ChartWrapper>
        </div>

        <div className="chart-cell">
          <TradersProfitLossChart
            syncId="tradersId"
            loading={tradersBFRLoading}
            tradersData={tradersBFRData}
            yaxisWidth={YAXIS_WIDTH}
            chartHeight={CHART_HEIGHT}
            csvFields={[
              { key: "profit", name: "profit(BFR)" },
              { key: "loss", name: "loss(BFR)" },
              { key: "profitCumulative", name: "profitCumulative(BFR)" },
              { key: "lossCumulative", name: "lossCumulative(BFR)" },
            ]}
            title={"Traders Profit vs. Loss (BFR)"}
          />
        </div> */}
      </div>
    </div>
  );
}

export default AboveBelow;
