import { useState } from "react";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

function ProductDropdown(props) {
  const { product } = props;
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  console.log(product,'product')
  return (
    <button
      className="dropdown"
      onClick={() => {
        setIsDropdownVisible(!isDropdownVisible);
      }}
    >
      {product}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.0"
        viewBox="0 0 512.000000 512.000000"
        preserveAspectRatio="xMidYMid meet"
        width={8}
        height={8}
        className="dropdown-arrow"
      >
        <g
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill="#fff"
          stroke="none"
        >
          <path d="M60 4207 c-30 -15 -60 -71 -60 -112 0 -17 8 -46 18 -65 18 -35 2409 -3040 2463 -3095 25 -25 37 -30 79 -30 40 0 54 5 74 25 14 14 574 714 1246 1555 799 1002 1224 1543 1231 1567 15 46 4 101 -26 135 l-23 28 -2489 2 c-1977 2 -2493 0 -2513 -10z" />
        </g>
      </svg>
      <div className={` ${isDropdownVisible ? "dropdown-items" : "invisible"}`}>
        <NavLink className="dropdown-item inner-nav-link" to="/" exact>
          Up/Down
        </NavLink>
        <NavLink className="dropdown-item inner-nav-link" to="/above-below">
          AboveBelow
        </NavLink>
      </div>
    </button>
  );
}

export default ProductDropdown;
