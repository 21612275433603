import { useMemo, useState } from "react";
import {
  tooltipFormatter,
  tooltipFormatterPercent,
  yaxisBFRFormatter,
  yaxisFormatter,
  yaxisFormatterPercent,
} from "../helpers";

export function useChartViewState({ controls, data }) {
  const [viewState, setViewState] = useState({
    isPercentsView: false,
  });

  const formattedData = useMemo(() => {
    if (!data) {
      return undefined;
    }

    if (viewState.isPercentsView && controls.convertToPercents) {
      return controls.convertToPercents(data);
    }

    return data;
  }, [data, viewState.isPercentsView, controls.converToPercents]);

  const togglePercentView = () => {
    setViewState((old) => ({ ...old, isPercentsView: !old.isPercentsView }));
  };

  return {
    viewState,
    togglePercentView,
    formattedData,

    itemsUnit: viewState.isPercentsView ? "%" : undefined,

    yaxisTickFormatter: viewState.isPercentsView
      ? yaxisFormatterPercent
      : yaxisFormatter,

    yaxisBFRTickFormatter: viewState.isPercentsView
      ? yaxisFormatterPercent
      : yaxisBFRFormatter,

    tooltipFormatter: viewState.isPercentsView
      ? tooltipFormatterPercent
      : tooltipFormatter,
  };
}
